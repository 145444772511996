import React, {useCallback, useMemo, useState} from 'react';
import {Descendant, Text} from 'slate'
import {useSlate} from 'slate-react';
import styled from 'styled-components';

import {ModalConfirmation} from '@dized/ui'

import {IconSelector} from './IconSelector';
import {ToolbarButton} from './ToolbarButton';
import {deserialize} from '../utils/html';

type TextData = Editor.TextData

type FoundMap = {[k: string]: boolean}

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  height: min-content;
  max-height: 80vh;
  overflow-y: auto;
`

const IconListContainer = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 1em;
  width: min-content;
  margin: 0 0.25em;
`

const HelpText = styled.div`
  grid-column: 1/-1;
`

const extractIcons = (
  nodes: Descendant[],
  locale: string,
  icons: IconData[] = [],
  found: FoundMap = {},
) => {
  nodes.forEach(node => {
    // Leaf
    if (Text.isText(node)) return

    // Extract icon information
    if (node.type === 'icon') {
      const icon = node as RichTextIcon
      const {attr, id} = icon

      // suppress duplicates
      if (!found[id]) {
        icons.push({
          ...icon,
          attr: {
            ...attr,
            locale, // override source locale with target locale
          },
        })
        found[id] = true
      }
    }

    // Recurse into children
    extractIcons(node.children, locale, icons, found)
  })

  return icons
}

export const IconButton = () => {
  const editor = useSlate()
  const [showModal, updateShowModal] = useState<boolean>(false)
  const {locale, source} = editor.getUserData();

  const icons = useMemo(
    () => {
      const text = (source as TextData)?.text ?? ''
      const parsed = deserialize(text)

      return extractIcons(parsed, locale)
    },
    [locale, source]
  )

  const onHideModal = useCallback(
    () => updateShowModal(false),
    []
  )

  return (
    <ToolbarButton
      disabled={icons.length === 0 || !editor.selection}
      onMouseDown={event => {
        event.preventDefault()
        updateShowModal(true)
      }}
    >
      <div>Icon</div>
      <ModalConfirmation
        customStyles={{
          width: 'fit-content',
          height: 'fit-content',
        }}
        header="Select Icon To Insert"
        onCancel={onHideModal}
        onClose={onHideModal}
        visible={showModal}
      >
        <ModalContent>
          <IconListContainer>
            <HelpText>
              Drag file on image or click to select file to upload
            </HelpText>
            {icons.map((icon, index) => (
              <IconSelector
                icon={icon}
                key={index}
                onClose={onHideModal}
              />
            ))}
          </IconListContainer>
        </ModalContent>
      </ModalConfirmation>
    </ToolbarButton>
  )
}
