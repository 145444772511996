import React from 'react';

import {MediaPlayButton} from '@dized/ui';

import {useAppSelector} from '../../app/hooks';
import {voiceSelector} from '../../slices/voice';
import {generateTTSURL} from '../../utils/tts';

type VoiceOverData = Editor.VoiceOverData

type TTSButtonProps = {
  fallbackText?: string
  locale: string
  preset: number
  value?: VoiceOverData
}

export const TTSButton = ({
  fallbackText = '',
  locale,
  preset,
  value,
}: TTSButtonProps) => {
  const {presets} = useAppSelector(voiceSelector)
  const {preset: presetInUse, text} = value ?? {preset}
  const url = generateTTSURL(
    presets,
    presetInUse,
    locale,
    text || fallbackText
  )

  return (
    <MediaPlayButton
      disabled={!url}
      assetObject={{
        _meta: {
          mimeType: 'audio/mpeg',
        },
        url,
      }}
    />
  )
}
