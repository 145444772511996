import React from 'react';
import {RenderLeafProps} from 'slate-react';
import styled from 'styled-components';

const SpoilerContainer = styled.span`
  background-color: rgba(128, 128, 128, 0.5);
`

export const Leaf = ({attributes, children, leaf}: RenderLeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }
  if (leaf.italic) {
    children = <em>{children}</em>
  }
  if (leaf.spoiler) {
    children = <SpoilerContainer>{children}</SpoilerContainer>
  }
  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}
