import React, {PropsWithChildren} from 'react';
import {Editor} from 'slate'
import {useSlate} from 'slate-react';

import {ToolbarButton} from './ToolbarButton';

const isMarkActive = (editor: Editor, format: RichTextMarkKeys) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const toggleMark = (editor: Editor, format: RichTextMarkKeys) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

type MarkButtonProps = PropsWithChildren<{
  format: RichTextMarkKeys
}>

export const MarkButton = ({children, format}: MarkButtonProps) => {
  const editor = useSlate()

  return (
    <ToolbarButton
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      {children}
    </ToolbarButton>
  )
}
