import {post} from '../../utils/apiRequest';

import {fetchAO1s} from './ao1';
import {fetchTR1s, saveTR1s} from './tr1';

type TextData = Editor.TextData
type Translatable = Editor.Translatable
type TranslatableMap = Editor.TranslatableMap
type VoiceOverData = Editor.VoiceOverData

const apiPath = '/api/v1/translation/vos'

type VoiceOverAPITranslatable = {
  assetId: string
  locale: string
  preset: number
  textId: string
  ttsEnabled: boolean
  versionChecksum: string
  voId: string
}

export const fetchVO1s = async (voIds: string[], locale: string): Promise<TranslatableMap> => {
  // NOTE: use POST variant of API endpoint to avoid URL length restrictions
  const vos = await post(`${apiPath}/${locale}`, {
    fields: ['assetId', 'preset', 'textId', 'ttsEnabled', 'versionChecksum', 'voId'],
    voIds,
  }) as VoiceOverAPITranslatable[]

  // fetch assets & texts
  const [aosMap, trsMap] = await Promise.all([
    fetchAO1s(
      vos
        .filter(({ttsEnabled}) => !ttsEnabled)
        .map(({assetId}) => assetId),
      locale
    ),
    fetchTR1s(
      vos
        .filter(({ttsEnabled}) => ttsEnabled)
        .map(({textId}) => textId),
      locale
    )
  ])

  // return targetId->Translatable map
  return vos.reduce(
    (map, {assetId, preset, textId, ttsEnabled, versionChecksum: checksum, voId: id}) => {
      const embeddedData = ttsEnabled ? trsMap[textId] : aosMap[assetId]
      const translatable: VoiceOverData = {
        // provide defaults for AssetData & TextData properties
        text: '',
        filename: '',
        type: '',
        url: '',

        // overwrites either AssetData or TextData properties
        ...embeddedData,

        // VoiceOverData properties
        assetId,
        checksum,
        id,
        locale,
        preset,
        textId,
        ttsEnabled,
      }
      map[id] = translatable
      return map
    },
    {} as TranslatableMap
  )
}

export const saveVO1s = async (translatables: Translatable[]) => {
  const vo1s = translatables as VoiceOverData[]

  // For text-to-speech we need to update the embedded TR1 object here
  const tr1s = vo1s
    .filter(({ttsEnabled}) => ttsEnabled)
    .map(translatable => {
      const {checksum, locale, text, textId: id} = translatable
      const textTranslatable: TextData = {checksum, locale, id, text}
      return textTranslatable
    })

  const data = vo1s.map(translatable => {
    const {
      assetId,
      checksum: versionChecksum,
      id: voId,
      locale,
      preset,
      textId,
      ttsEnabled
    } = translatable
    const data: VoiceOverAPITranslatable = {
      assetId,
      locale,
      preset,
      textId,
      ttsEnabled,
      versionChecksum,
      voId,
    }
    return data
  })

  return Promise.all([
    post(apiPath, data),
    saveTR1s(tr1s),
  ])
}
