import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';

import {COLOR} from '@dized/ui';

import {Asset} from '../Asset';

type AssetData = Editor.AssetData

type DropAreaProps = {
  isDragActive: boolean
}
const DropArea = styled.div<DropAreaProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: ${COLOR.BLACK};
  background-color: ${({isDragActive}) => isDragActive ? COLOR.LIGHT_TURQUOISE : COLOR.SHADOW_GRAY};
  padding: ${({isDragActive}) => isDragActive ? '0.25em' : ''} 0;
`

const DropPlaceholder = styled.div`
  padding: 1em;
`

type AssetDropzoneProps = {
  assetData: AssetData
  className?: string
  disabled?: boolean
  onFileSelected: (file: File) => void
  showOriginal?: boolean
}

export const AssetDropzone = ({
  assetData,
  className,
  disabled,
  showOriginal,
  onFileSelected
}: AssetDropzoneProps) => {
  const onDrop = useCallback(
    async (files: File[]) => {
      if (files.length < 1) return

      onFileSelected(files[0])
    },
    [onFileSelected]
  )
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  // just render the asset if drop is disabled
  if (disabled)
    return <Asset className={className} showOriginal={showOriginal} {...assetData} />

  return (
    <DropArea
      {...getRootProps()}
      isDragActive={isDragActive}
    >
      <input {...getInputProps()} />
      {assetData && assetData.type
        ? <Asset className={className} showOriginal={showOriginal} {...assetData} />
        : <DropPlaceholder>Drag file here or click to select file to upload...</DropPlaceholder>
      }
    </DropArea>
  )
}
