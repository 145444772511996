import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {RESET_PROJECT} from './project';
import {RootState} from '../app/store';
import {get} from '../utils/apiRequest';

// import {simTranslationIndex} from './simulation-data';

type ContextMap = TranslationIndex.ContextMap
type IObject = TranslationIndex.IndexObject;

type TranslationState = {
  contexts: ContextMap
  filteredList: string[]
  images: IObject[]
  index: IObject[]
  locales: string[]
  masterLocale: string
  wordCount: number
}

const defaultLocale = 'en-US'

const initialState: TranslationState = {
  contexts: {},
  filteredList: [],
  images: [],
  index: [],
  locales: [],
  masterLocale: defaultLocale,
  wordCount: 0,
}

const apiPath = '/api/v1/translation'

export const FETCH_GAME_INDEX = createAsyncThunk<
  IObject[],
  void,
  {state: RootState}
>(
  'FETCH_GAME_INDEX',
  async (_, {getState}) => {
    const {
      game: {title: {text: gameTitle = ''} = {}},
      project: {gameId},
    } = getState()

    return Promise.resolve()
      .then(() => get(`${apiPath}/game/${gameId}/index`) as Promise<IObject[]>)
      // override context group label with something more user friendly
      .then(index => index.map(entry => ({
        ...entry,
        targetContextGroupLabel: `Game information for '${gameTitle}'`,
      })))
    // return simTranslationIndex
  }
)

export const FETCH_PROJECT_INDEX = createAsyncThunk<
  IObject[],
  void,
  {state: RootState}
>(
  'FETCH_PROJECT_INDEX',
  async (_, {getState}) => {
    const {project: {projectId}} = getState()

    return get(`${apiPath}/project/${projectId}/index`) as Promise<IObject[]>
    // return simTranslationIndex
  }
)

type LocalesAPIResponse = {
  locale: string
  master: boolean
}[]

const fetchLocalesAction = async (type: string, id: string, targetLocale: string) => {
  const locales = await get(`${apiPath}/${type}/${id}`) as LocalesAPIResponse

  // Sanity check: target locale must be in the list of locales
  if (!locales.some(({locale}) => locale === targetLocale))
    throw new Error('invalid locale')

  return locales

}
export const FETCH_GAME_LOCALES = createAsyncThunk<
  LocalesAPIResponse,
  {targetLocale: string},
  {state: RootState}
>(
  'FETCH_GAME_LOCALES',
  async ({targetLocale}, {getState}) => {
    const {project: {gameId}} = getState()
    return fetchLocalesAction('game', gameId, targetLocale)
  }
)

export const FETCH_PROJECT_LOCALES = createAsyncThunk<
  LocalesAPIResponse,
  {targetLocale: string},
  {state: RootState}
>(
  'FETCH_PROJECT_LOCALES',
  async ({targetLocale}, {getState}) => {
    const {project: {projectId}} = getState()
    return fetchLocalesAction('project', projectId, targetLocale)
  }
)

type ReindexResponse = {
  id: string
}

export const REINDEX_GAME = createAsyncThunk(
  'REINDEX_GAME',
  async (gameId: string) => get(`${apiPath}/game/${gameId}/reindex`)
)

export const REINDEX_PROJECT = createAsyncThunk(
  'REINDEX_PROJECT',
  async (projectId: string) => {
    // queue reindex request
    const {id} = await get(`${apiPath}/project/${projectId}/reindex`) as ReindexResponse
    const baseUrl = `${apiPath}/reindex/${id}`

    // provide faster feedback for short re-indices, reduce API pings for longer ones
    let timeout = 1 // [seconds]

    // ESlint warns about functions defined in a loop referring to a non-loop variable
    // Move the function definition outside of the loop
    const timoutFn = (resolve: Function) => setTimeout(resolve, timeout * 1000)

    while (true) {
      // wait 1, 2, 4 or 8 seconds
      await new Promise(timoutFn)

      // check status of reindex request
      const completed = await get(`${baseUrl}/status`) as boolean
      if (completed) break

      // wait longer next time around
      if (timeout < 8) timeout *= 2
    }

    // fetch reindex result or error
    return get(`${baseUrl}/result`)
  }
)

const sortFilteredList = (
  contexts: ContextMap,
  filtered: string[],
): [string[], number] => [
  // ID list sorted by lexically by context name
  filtered.sort(
    (a, b) => contexts[a].label.localeCompare(contexts[b].label)
  ),
  // sum of word counts from filtered contexts
  filtered.reduce(
    (sum, id) => sum + contexts[id].wordCount,
    0
  ),
]

export const translationSlice = createSlice({
  name: 'translation',
  initialState,
  reducers: {
    GENERATE_CONTEXT_MAP(state) {
      const {index} = state
      const [contexts, imagesMap] = index.reduce(
        ([contexts, images], entry) => {
          const {
            meta: {mimeType, wordCount = 0},
            targetCategory,
            targetContextGroupId,
            targetContextGroupLabel,
            targetId,
            targetType,
          } = entry
          const context = contexts[targetContextGroupId]
          const targetKey = `${targetType}${targetId}`

          if (context) {
            context.translations[targetKey] = entry
            context.wordCount += wordCount
          } else {
            contexts[targetContextGroupId] = {
              category: targetCategory,
              id: targetContextGroupId,
              label: targetContextGroupLabel,
              translations: {
                [targetKey]: entry,
              },
              wordCount,
            }
          }

          // NOTE 1: MIME type meta implies AO1 entry
          // NOTE 2: targetId for AO1 is the assetId
          if (
            mimeType &&
            mimeType.startsWith('image/') &&
            !images[targetId]
          )
            images[targetId] = entry

          return [contexts, images]
        },
        [
          {} as ContextMap,
          {} as {[assetId: string]: IObject},
        ]
      )
      const [filteredList, wordCount] = sortFilteredList(
        contexts,
        Object.values(contexts).map(({id}) => id)
      )
      const images = Object.values(imagesMap)

      Object.assign(state, {contexts, filteredList, images, wordCount})
    },
    SET_FILTERED_LIST(state, {payload}: PayloadAction<string[]>) {
      const {contexts} = state
      const [filteredList, wordCount] = sortFilteredList(contexts, payload)
      Object.assign(state, {filteredList, wordCount})
    }
  },
  extraReducers: builder => {
    builder
      .addCase(RESET_PROJECT, () => initialState)
      .addMatcher<PayloadAction<IObject[]>>(
        ({type}) => !!/_INDEX\/fulfilled$/.exec(type),
        (state, {payload: index}) => ({
          ...state,
          index,
        })
      )
      .addMatcher<PayloadAction<LocalesAPIResponse>>(
        ({type}) => !!/_LOCALES\/fulfilled$/.exec(type),
        (state, {payload}) => ({
          ...state,
          locales: payload.map(({locale}) => locale),
          masterLocale: payload.find(({master}) => master)?.locale ?? defaultLocale,
        })
      )
  },
})

export const translationSelector = (state: RootState) => state[translationSlice.name]
export const {GENERATE_CONTEXT_MAP, SET_FILTERED_LIST} = translationSlice.actions
