import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import {createLogger} from 'redux-logger';

import {applicationSlice} from '../slices/application';
import {assetSlice} from '../slices/asset';
import {editorSlice} from '../slices/editor';
import {gameSlice} from '../slices/game';
import {projectSlice} from '../slices/project';
import {translationSlice} from '../slices/translation';
import {voiceSlice} from '../slices/voice';

const logger = createLogger({
  collapsed: true,
});

export const store = configureStore({
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(
    logger,
  ),
  reducer: {
    [applicationSlice.name]: applicationSlice.reducer,
    [assetSlice.name]: assetSlice.reducer,
    [editorSlice.name]: editorSlice.reducer,
    [gameSlice.name]: gameSlice.reducer,
    [projectSlice.name]: projectSlice.reducer,
    [translationSlice.name]: translationSlice.reducer,
    [voiceSlice.name]: voiceSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
