import React, {useCallback, ChangeEvent} from 'react';
import styled from 'styled-components';

import {Input, Label, ListItemSelection} from '@dized/ui';

import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {
  applicationSelector,
  SET_FILTER_CATEGORIES,
  SET_FILTER_TITLE,
  TargetCategories,
  TargetCategoryFilterMap,
} from '../../slices/application';
import {translationSelector, SET_FILTERED_LIST} from '../../slices/translation';

type ContextMap = TranslationIndex.ContextMap
type TargetCategory = TranslationIndex.TargetCategory

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 1em;
  margin: 0.5em 0;
`

const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
`
const filterContextList = (
  contextMap: ContextMap,
  categories: TargetCategoryFilterMap,
  title: string,
): string[] => {
  let contexts = Object
    .values(contextMap)
    .filter(({category}) => categories[category])

  if (title) {
    const match = title.toLocaleLowerCase()
    contexts = contexts.filter(({label}) => label.toLocaleLowerCase().includes(match))
  }

  return contexts.map(({id}) => id)
}

export const Filter = () => {
  const {filter: {categories, title}} = useAppSelector(applicationSelector)
  const {contexts} = useAppSelector(translationSelector)
  const dispatch = useAppDispatch()

  const onCategoryChange = useCallback(
    (
      contexts: ContextMap,
      categories: TargetCategoryFilterMap,
      title: string,
      category: TargetCategory,
      checked: boolean,
    ) => {
      const cloned = {...categories}
      cloned[category] = checked
      dispatch(SET_FILTER_CATEGORIES(cloned))
      dispatch(SET_FILTERED_LIST(filterContextList(contexts, cloned, title)))
    },
    [dispatch]
  )

  const onTitleChange = useCallback(
    (
      contexts: ContextMap,
      categories: TargetCategoryFilterMap,
      event: ChangeEvent<HTMLInputElement>,
    ) => {
      const title = event.target.value
      dispatch(SET_FILTER_TITLE(title))
      dispatch(SET_FILTERED_LIST(filterContextList(contexts, categories, title)))
    },
    [dispatch]
  )

  return (
    <>
      <h2>Filter</h2>
      <Container>
        <Label>Title</Label>
        <Input
          className="test"
          onChange={
            (event: ChangeEvent<HTMLInputElement>) => onTitleChange(contexts, categories, event)
          }
          placeholder="Enter filter string..."
          type="search"
          value={title}
        />
        <Label>Type</Label>
        <ListItemContainer>
          {TargetCategories.map((category, index) => (
            <ListItemSelection
              checked={categories[category]}
              key={index}
              onChange={
                (checked: boolean) => onCategoryChange(contexts, categories, title, category, checked)
              }
            >
              {category}
            </ListItemSelection>
          ))}
        </ListItemContainer>
      </Container>
    </>
  )
}
