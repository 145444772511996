import React, {MutableRefObject} from 'react';
import styled from 'styled-components';

import {COLOR} from '@dized/ui';

import {WidgetSelector} from './widget';

import type {ListenForChangesType} from '.';

type CloneCallback = Editor.CloneCallback
type IObject = TranslationIndex.IndexObject
type Translatable = Editor.Translatable

enum StatusColors {
  NONE = 'unset',
  MISSING = 'red',
  OUTDATED = 'orange',
  OK = 'green',
}

type ContainerProps = {
  color: string
}

const Container = styled.div<ContainerProps>`
  padding: 0.5em;
  border-radius: 4px;
  background-color: ${({color}) => color};
`

const WidgetContainer = styled.div`
  width: 100%;
  padding: 0.5em;
  background-color: ${COLOR.LIGHT_GRAY};
`

type TranslatableEditorProps = {
  className?: string
  cloneRef?: MutableRefObject<CloneCallback | undefined>
  entry: IObject
  listenForChanges?: ListenForChangesType
  locale?: string
  onChange?: (translatable: Translatable) => void
  source?: Translatable
  status?: keyof typeof StatusColors
  value?: Translatable
}

const dummyRef = {
  current: undefined,
}

export const TranslatableEditor = ({
  cloneRef = dummyRef,
  className,
  entry,
  listenForChanges,
  locale = '',
  onChange,
  source,
  status = 'NONE',
  value,
}: TranslatableEditorProps) => {
  const {targetChecksum, targetType} = entry;
  const Widget = WidgetSelector[targetType]

  return (
    <Container
      className={className}
      color={StatusColors[status]}
    >
      <WidgetContainer>
        <Widget
          checksum={targetChecksum}
          cloneRef={cloneRef}
          entry={entry}
          listenForChanges={listenForChanges}
          locale={locale}
          onChange={onChange}
          source={source}
          value={value}
        />
      </WidgetContainer>
    </Container>
  )
}
