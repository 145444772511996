import React from 'react';
import styled from 'styled-components';

import {MediaPlayButton} from '@dized/ui';

import {Row} from '../StyledComponents';

type AssetData = Editor.AssetData

const Filename = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.5em;
`

const Image = styled.img `
  max-width: fit-content;
  max-height: fit-content;
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ShowFullImageLink = styled.a`
  font-size: smaller;
  margin-bottom: 0.25em;
`

type AssetProps = AssetData & {
  className?: string
  showOriginal?: boolean
}

export const Asset = ({className, filename, showOriginal, type, url}: AssetProps) => {
  if (/^application\/octet-stream/.exec(type)) {
    return (
      <Row className={className}>
        <div>Model</div>
        <Filename>{filename}</Filename>
      </Row>
    )

  } else if (/^audio\//.exec(type)) {
    return (
      <Row className={className}>
        <MediaPlayButton
          assetObject={{
            _meta: {
              mimeType: type,
            },
            url,
          }}
        />
        <Filename>{filename}</Filename>
      </Row>
    )

  } else if (/^image\//.exec(type)) {
    if (showOriginal)
      return (
        <img
          alt="Broken link"
          className={className}
          src={url}
        />
      )

    // scale down image and provide link to show the original
    return (
      <ImageContainer className={className}>
        <Row className={className}>
          <ShowFullImageLink
            href={url}
            // prevent click to propagate up to potential AssetDropZone parent
            onClick={event => event.stopPropagation()}
            rel="noreferrer"
            target='_blank'
          >
            Show full image
          </ShowFullImageLink>
          <Filename>{filename}</Filename>
        </Row>
        <Image
          alt="Broken link"
          // limit image size to keep the UI sane for huge image assets
          // NOTE: URL has cache buster parameter, so we can safely add more
          src={`${url}&size=[400,400]&max=true&withoutEnlargement=true`}
        />
      </ImageContainer>
    )

  } else
    return (
      <div className={className}>
        Can't render '{filename}'
      </div>
    )
}
