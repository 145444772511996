import React, {useCallback} from 'react';

import {TextInput} from '../../TextInput';
import {RichTextInput} from '../../RichTextInput';
import {WidgetProps} from '.';

type TextData = Editor.TextData

export const TR1 = ({checksum, cloneRef, entry, locale, onChange, source, value}: WidgetProps) => {
  const {targetSubType} = entry;
  // narrow down union type to TextData
  const text = (value as TextData)?.text ?? ''

  const onCopy = useCallback(
    async (source: TextData) => {
      const {text} = source
      return {text} as TextData
    },
    []
  )
  cloneRef.current = onCopy

  // useCallback can't be behind conditional
  const onUpdate = onChange && (
    (text: string) => {
      const {targetId: id} = entry
      const translatable: TextData = {
        checksum,
        id,
        locale,
        text,
      }
      onChange(translatable);
    }
  )

  if (targetSubType)
    return (
      <RichTextInput
        locale={locale}
        onChange={onUpdate}
        source={source}
        value={text}
      />
    )

  return (
    <TextInput
      onChange={onUpdate}
      value={text}
    />
  )
}
