import React, {useCallback, useState} from 'react';
import styled from 'styled-components';

import {Pagination} from '@dized/ui';

import {ContextEditor} from '../ContextEditor';
import {Message, MessageContainer} from '../StyledComponents';

import {useAppSelector} from '../../app/hooks';
import {translationSelector} from '../../slices/translation';

type IObject = TranslationIndex.IndexObject

const StyledPagination = styled(Pagination)`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-top: 0.5em;
`

interface ImagesProps {
  locale: string
}

export const Images = ({locale}: ImagesProps) => {
  const {images} = useAppSelector(translationSelector)
  const [entries, updateEntries] = useState<IObject[]>([])

  // NOTE: Pagination onChange= should be a stable callback
  const onChange = useCallback(
    (page: number, itemsPerPage: number) => updateEntries(
      itemsPerPage
        ? images.slice(
            page       * itemsPerPage,
            (page + 1) * itemsPerPage
          )
        : images // show all items
    ),
    [images]
  )

  if (images.length === 0)
    return (
      <MessageContainer>
        <Message>Project does not have any images</Message>
      </MessageContainer>
    )

  return (
    <>
      <StyledPagination
        maxItems={images.length}
        onChange={onChange}
      />
      {entries.length > 0 && (
        <ContextEditor
          entries={entries}
          hideTitle={true}
          locale={locale}
          onClose={() => {}}
        />
      )}
    </>
  )
}
