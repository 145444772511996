import React, {ReactNode} from 'react';
import styled from 'styled-components';

import {Button, COLOR, GridDataTable as GDTable} from '@dized/ui';

/*
 * No idea why the following doesn't work...
 *
type GridDataTableProps = typeof GDTable
 *
 * ...so re-create type to make TS happy.
 */
type GridDataTableColumnProps = {
  renderCell: (data: any) => ReactNode
  sortCell?: (dataA: any, dataB: any, reverse: boolean) => boolean
  style?: (data: any, rowIndex: number) => string
  template?: string
  title: string
}

interface GridDataTableProps {
  className?: string
  columns: GridDataTableColumnProps[]
  data: unknown[]
  rowStyle?: (data: any, rowIndex: number) => string
}

export const GridButton = styled(Button)`
  margin: 0 5px;
  min-width: fit-content;
`

const StyledGridDataTable = styled(GDTable)`
  border-left: 1px solid ${COLOR.BACKGROUND_WHITE};
  border-right: 1px solid ${COLOR.BACKGROUND_WHITE};
`

export const GridDataTable = (props: GridDataTableProps) => (
  <StyledGridDataTable
    rowStyle={(_: any, rowIndex: number) => rowIndex === 0
      ? `
        background-color: ${COLOR.BACKGROUND_WHITE};
      `
      : `
        border-bottom: 1px solid ${COLOR.BACKGROUND_WHITE};
        padding: 5px 1em;
      `
    }
    {...props}
  />
)
