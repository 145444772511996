const onPreventUnload = (event: BeforeUnloadEvent) => {
  // Cancel the event as stated by the standard
  event.preventDefault();
  // Chrome requires returnValue to be set
  event.returnValue = '';
}

export const setCloseProtection = (locked: boolean) => {
  if (locked) {
    window.addEventListener('beforeunload', onPreventUnload);
  } else {
    window.removeEventListener('beforeunload', onPreventUnload);
  }
}
