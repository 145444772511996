import React, {useMemo, useCallback, useState} from 'react';
import {BaseEditor, Descendant, createEditor} from 'slate'
import {Editable, ReactEditor, Slate, withReact} from 'slate-react';
import {HistoryEditor, withHistory} from 'slate-history'

import {Element} from './components/Element'
import {Leaf} from './components/Leaf'
import {Toolbar} from './components/Toolbar'
import {deserialize, serialize} from './utils/html'
import {UserDataEditor, withIcons, withUserData} from './utils/plugin'

// initialize Slate Typescript support with our model types
declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor & UserDataEditor
    Element: RichTextElement
    Text: RichText
  }
}

type Translatable = Editor.Translatable

type RichTextInputProps = {
  locale: string
  onChange?: (value: string) => void
  source?: Translatable
  value: string
}

export const RichTextInput = ({locale, onChange, source, value}: RichTextInputProps) => {
  const editor = useMemo(
    () => withUserData(withIcons(withHistory(withReact(createEditor()))), {locale, source}),
    [locale, source]
  )
  const [internalValue, setInternalValue] = useState<Descendant[]>(deserialize(value))

  const renderElement = useCallback(
    props => <Element {...props} />,
    []
  )

  const renderLeaf = useCallback(
    props => <Leaf {...props} />,
    []
  )

  const onUpdate = useCallback(
    (value: Descendant[]) => {
      if (!onChange) return;

      // update internal state
      setInternalValue(value)

      // forward content changes
      const isAstChange = editor.operations.some(
        op => 'set_selection' !== op.type
      )
      if (isAstChange) onChange(serialize(value))
    },
    [editor, onChange]
  )

  return (
    <Slate
      editor={editor}
      onChange={onUpdate}
      value={internalValue}
    >
      {onChange && <Toolbar />}
      <Editable
        readOnly={!onChange}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
      />
    </Slate>
  )
}
