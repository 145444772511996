import React, {MouseEventHandler, PropsWithChildren} from 'react';
import styled from 'styled-components';

type ToolbarButtonContainerProps = {
  active?: boolean
  disabled?: boolean
  onMouseDown: MouseEventHandler<HTMLSpanElement>
}

const ToolbarButtonContainer = styled.span<ToolbarButtonContainerProps>`
  color: ${({disabled}) => disabled ? 'lightgrey' : 'black'};
  background-color: ${({active}) => active ? 'slategrey' : 'unset'};
  font-weight: 700;
  min-width: 1em;
  text-align: center;
  padding: 0.25em;
  margin-right: 0.25em;
  margin-bottom: 0.25em;
  border: solid ${({disabled}) => disabled ? 'lightgrey' : 'black'} 2px;
  border-radius: 4px;
`

type ToolbarButtonProps = PropsWithChildren<ToolbarButtonContainerProps>

export const ToolbarButton = ({children, ...rest}: ToolbarButtonProps) => (
  <ToolbarButtonContainer {...rest}>
    {children}
  </ToolbarButtonContainer>
)
