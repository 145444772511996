import React from 'react';

import {GridButton, GridDataTable} from '../GridDataTable';

import {useAppSelector} from '../../app/hooks';
import {translationSelector} from '../../slices/translation';

type Context = TranslationIndex.Context

const numberCellStyle = `
  flex-direction: row-reverse;
  align-content: flex-start;
`

type ContextListProps = {
  onEditContext: (contextId: string) => void
}

export const ContextList = ({onEditContext}: ContextListProps) => {
  const {
    contexts,
    filteredList,
    wordCount,
  } = useAppSelector(translationSelector)

  return (
    <GridDataTable
      columns={[
        {
          template: '1fr',
          title: 'Context',
          renderCell: ({label}: Context) => label,
        },
        {
          title: 'Items',
          renderCell: ({translations}: Context) => Object.keys(translations).length,
          style: () => numberCellStyle,
        },
        {
          title: `Words (${wordCount})`,
          renderCell: ({wordCount}: Context) => wordCount,
          style: () => numberCellStyle,
        },
        {
          title: '',
          renderCell: ({id}: Context) => (
            <GridButton onClick={() => onEditContext(id)}>
              Edit
            </GridButton>
          ),
        },
      ]}
      data={filteredList.map(id => contexts[id])}
    />
  )
}
