import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {RESET_PROJECT} from './project';
import {RootState} from '../app/store';
import {get} from '../utils/apiRequest';

type VoiceState = {
  presets: VoicePreset[]
}

const initialState: VoiceState = {
  presets: [],
}

const apiPath = '/api/v1/tutorial'

type TutorialAPIResponse = {
  _meta?: {
    voicePresets?: VoicePreset[]
  }
}

export const FETCH_VOICE_PRESETS = createAsyncThunk<
    TutorialAPIResponse | undefined,
    void,
    {state: RootState}
  >(
  'FETCH_VOICE_PRESETS',
  async (_, {getState}) => {
    const {project: {projectId, type}} = getState()

    // only tutorials have voice presets
    if (type !== 'tct') return;

    // disable feature if we are not allowed to access the tutorial
    // @TODO: provide the presets via translation-api?
    try {
      const response = await get(`${apiPath}/${projectId}/json`) as Promise<TutorialAPIResponse>
      return response;
    } catch (_) {
      return;
    }
  },
)

export const voiceSlice = createSlice({
  name: 'voice',
  initialState,
  reducers: {
    // no synchronous reducers at the moment
  },
  extraReducers: builder => {
    builder
      .addCase(RESET_PROJECT, () => initialState)
      .addCase(FETCH_VOICE_PRESETS.fulfilled, (state, {payload}) => {
        if (!payload) return state

        const voicePresets = payload._meta?.voicePresets
        if (!voicePresets) return state

        return {
          ...state,
          presets: [...voicePresets],
        }
      })
  },
})

export const voiceSelector = (state: RootState) => state[voiceSlice.name]
