import styled from 'styled-components';

export const Message = styled.div`
  font-size: 200%;
  padding: 1em;
  color: red;
`

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-weight: 900;
`

export const Row = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`

export const TopLevelContainer = styled.div`
  padding: 0.5em;
`;
