import shaDigest from 'sha.js'

import {apiRequest, get, post} from '../../utils/apiRequest';

type AssetData = Editor.AssetData
type Translatable = Editor.Translatable
type TranslatableMap = Editor.TranslatableMap

const apiPath = '/api/v1/assets/asset'

type AssetDataAPIData = {
  checksum: string
  meta: object
  modified: string
  size: number
  type: string
}

type AssetAPIData = {
  id: string
  name: string
  assetData: AssetDataAPIData[]
}

type Loopback3APIResponseData = {
  data: AssetAPIData[]
}

type Loopback3APICreateResponseData = {
  id: string
}

type Loopback3APIUploadResponseData = {
  data: AssetAPIData[][]
}

export const getBaseUrl = (id: string, locale: string) => `${apiPath}/${id}/${locale}`

export const getDownloadUrl = async (baseURL: string, hashInput: string) => {
  // calculate SHA-1 of input string
  let hash

  /*
    * From DOM documentation:
    *
    * SubtleCrypto
    *    Secure context
    *    This feature is available only in secure contexts (HTTPS),
    *    in some or all supporting browsers.
    */
  if (crypto.subtle) {
    const encoder = new TextEncoder()
    const data = encoder.encode(hashInput)
    const hashBuffer = await crypto.subtle.digest('SHA-1', data)

    // convert digest to hex string
    const octets = new Uint8Array(hashBuffer)
    hash = Array
      .from(octets)
      .map(octet => octet.toString(16).padStart(2, '0'))
      .join('')

  } else {
    hash = shaDigest('sha1').update(hashInput).digest('hex')
  }

  // add hash as cache buster - otherwise browser may show obsolete asset content
  return `${baseURL}/download?hash=${hash}`
}

export const cloneAO1 = async (source: Translatable, locale: string): Promise<AssetData> => {
  const {id, locale: sourceLocale} = source
  const baseURL = `${apiPath}/${id}`

  // Asset + AssetData for locale
  const {
    data: [{
      name: filename,
      assetData: [{
        checksum,
        modified,
        size,
        type,
      }]
    }],
  } = await get(
    `${baseURL}/${sourceLocale}/${locale}/clone`
  ) as Loopback3APIResponseData
  const url = await getDownloadUrl(`${baseURL}/${locale}`, `${type}${size}${checksum}${modified}`)

  return {
    checksum,
    filename,
    id,
    locale,
    type,
    url,
  }
}

export const fetchAO1s = async (aoIds: string[], locale: string): Promise<TranslatableMap> => {
  if (aoIds.length === 0) return {}

  // Loopback3 query filter
  const query = {
    where: {
      id: {
        inq: aoIds,
      }
    },
    fields: { // fields from Asset instance to include in response
      id: true,
      name: true,
    },
    include: {
      relation: 'assetData',
      scope: {
        where: {
          locale,
        },
        fields: { // fields from AssetData instance to include in response
          checksum: true,
          modified: true,
          size: true,
          type: true,
        },
      },
    },
  }
  const aos = await get(
    `${apiPath}?filter=${JSON.stringify(query)}`,
  ) as AssetAPIData[]

  // AssetData can be empty if there's no translations tied to ao as we filter them by locale
  // return targetId->Translatable map
  return await aos.filter(ao => ao?.assetData?.length).reduce(
    async (
      promise,
      {
        id,
        name: filename,
        assetData: [{
          checksum,
          modified,
          size,
          type,
        }],
      }
    ) => {
      const baseURL = getBaseUrl(id, locale)
      const url = await getDownloadUrl(baseURL, `${type}${size}${checksum}${modified}`)
      const translatable: AssetData = {
        checksum,
        filename,
        id,
        locale,
        type,
        url,
      }

      const map = await promise
      map[id] = translatable
      return map
    },
    Promise.resolve({} as TranslatableMap)
  )
}

const createAsset = async (name: string, locale: string, translationFolderId?: string) => {
  if (!translationFolderId)
    throw new Error('Trying to create new asset without translation folder')

    const now = new Date()
  const {id} = await post(
    apiPath,
    {
      created: now,
      folder: translationFolderId,
      locale,
      modified: now,
      name,
    }
  ) as Loopback3APICreateResponseData

  return id;
}

export const saveAO1 = async (
  translatable: Translatable,
  file: File,
  translationFolderId?: string
): Promise<AssetData> => {
  const {checksum, locale} = translatable
  // create new asset if no asset ID is specified
  const id = translatable.id || await createAsset(file.name, locale, translationFolderId)
  const baseURL = getBaseUrl(id, locale)

  // asset-api expects file data behind Form entry file=
  const formData = new FormData();
  formData.append('file', file);

  // Asset + AssetData for locale
  const {
    data: [
      [{
        name: filename,
        assetData: [{
          modified,
          size,
          type,
        }]
      }],
    ]
  } = await apiRequest(
    `${baseURL}/${checksum}/upload`,
    'POST', {
      body: formData,
    }
  ) as Loopback3APIUploadResponseData
  const url = await getDownloadUrl(baseURL, `${type}${size}${checksum}${modified}`)

  return {
    ...translatable,
    filename,
    id,
    type,
    url,
  }
}
