import React from 'react';
import {RenderElementProps, useSelected} from 'slate-react';
import styled from 'styled-components';

type IconContainerProps = {
  selected: boolean
}

const ICON_BORDER = '3px';
const IconContainer = styled.span<IconContainerProps>`
  display: inline-flex;
  padding: ${({selected}) => selected ? '0' : ICON_BORDER};
  border: ${({selected}) => selected ? `solid ${ICON_BORDER} black` : 'unset'};
`

export const Icon = ({attributes, children, element}: RenderElementProps) => {
  const isSelected = useSelected()
  const {url} = element as RichTextIcon

  return (
    <IconContainer
      selected={isSelected}
      {...attributes}
    >
      <img
        src={url}
        alt=""
      />
      {children /* Slate needs this for DOM <-> model syncrhonization */}
    </IconContainer>
  )
}
