import React, {useCallback} from 'react';
import styled from 'styled-components';

import {AssetDropzone} from '../../AssetDropzone';

import {WidgetProps} from '.';
import {useAppDispatch} from '../../../app/hooks';
import {CLONE_ASSET, UPLOAD_ASSET} from '../../../slices/editor';

type AssetData = Editor.AssetData

const StyledAssetDropzone = styled(AssetDropzone)`
  max-width: 35vw;
`

export const AO1 = ({checksum, cloneRef, entry, locale, onChange, value}: WidgetProps) => {
  const assetData = value as AssetData
  const dispatch = useAppDispatch()

  const onCopy = useCallback(
    async (source: AssetData) => {
      await dispatch(CLONE_ASSET({entry, source, locale}))

      return undefined
    },
    [dispatch, entry, locale]
  )
  cloneRef.current = onCopy

  const onFileSelected = useCallback(
    async (file: File) => {
      const {targetId: id} = entry

      return dispatch(UPLOAD_ASSET({
        entry,
        file,
        translatable: {
          checksum,
          id,
          locale,
        },
      }))
    },
    [checksum, dispatch, entry, locale]
  )

  return (
    <StyledAssetDropzone
      assetData={assetData}
      disabled={!onChange}
      onFileSelected={onFileSelected}
    />
  )
}
