import {FunctionComponent, MutableRefObject} from 'react';

import {AO1} from './AO1'
import {TR1} from './TR1'
import {VO1} from './VO1'

import type {ListenForChangesType} from '..';

type CloneCallback = Editor.CloneCallback
type IObject = TranslationIndex.IndexObject
type TargetType = TranslationIndex.TargetType
type Translatable = Editor.Translatable

export type WidgetProps = {
  checksum: string
  cloneRef: MutableRefObject<CloneCallback | undefined>
  entry: IObject
  listenForChanges?: ListenForChangesType
  locale: string
  onChange?: (translatable: Translatable) => void
  source?: Translatable
  value?: Translatable
}
type Widget = FunctionComponent<WidgetProps>

export const WidgetSelector: { [type in TargetType]:  Widget} = {
  'ao1': AO1,
  'tr1': TR1,
  'vo1': VO1,
}
