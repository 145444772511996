import React, {useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {AsyncThunkAction} from '@reduxjs/toolkit';

import {Button, ModalConfirmation, Tab, TabNavigation} from '@dized/ui';

import {Contexts} from '../Contexts';
import {ErrorMessage} from '../ErrorMessage';
import {Header} from '../Header';
import {Images} from '../Images';
import {Spinner} from '../Spinner';
import {Message, MessageContainer, TopLevelContainer} from '../StyledComponents';

import {useAppSelector, useAppDispatch} from '../../app/hooks';
import {useSetPageTitle} from '../../hooks/useSetPageTitle';
import {applicationSelector, SET_ERROR} from '../../slices/application';
import {FETCH_ASSET_FOLDERS} from '../../slices/asset';
import {FETCH_GAME_LATEST} from '../../slices/game';
import {FETCH_PROJECT, RESET_PROJECT} from '../../slices/project';
import {
  translationSelector,
  FETCH_PROJECT_INDEX,
  FETCH_PROJECT_LOCALES,
  GENERATE_CONTEXT_MAP,
  REINDEX_PROJECT,
} from '../../slices/translation';
import {FETCH_VOICE_PRESETS} from '../../slices/voice';
import {setCloseProtection} from '../../utils/closeProtection';

interface TabContentProps {
  locale: string
  onDontHide: (donthide: boolean) => void
}

const tabs: {[key: string]: (props: TabContentProps) => JSX.Element} = {
  'Edit Contexts': ({locale, onDontHide}) => (
    <Contexts
    locale={locale}
    onDontHide={onDontHide}
    />
  ),
  'Upload images': ({locale}) => (
    <Images
      locale={locale}
    />
  ),
}

type RouteParams = {
  locale: string
  projectId: string
}

export const Project = () => {
  const {locale, projectId} = useParams<RouteParams>()
  const {error} = useAppSelector(applicationSelector)
  const {index} = useAppSelector(translationSelector)
  const dispatch = useAppDispatch()
  const [loading, updateLoading] = useState<boolean>(true)
  const [reIndexed, updateReIndexed] = useState<boolean>(false)
  const [showConfirmation, updateShowConfirmation] = useState<boolean>(false)
  const [activeTab, updateActiveTab] = useState<string>(Object.keys(tabs)[0])
  const [disableTabNavigation, updateDisableTabNavigation] = useState<boolean>(false)

  const onHideConfirmation = useCallback(
    () => updateShowConfirmation(false),
    []
  )

  const triggerReindex = useCallback(async (reIndexed: boolean) => {
    updateLoading(true)

    try {
      setCloseProtection(true)
      await dispatch(REINDEX_PROJECT(projectId))
      updateReIndexed(!reIndexed)
    } catch (error) {
      dispatch(SET_ERROR(error as Error))
    }

    setCloseProtection(false)
  }, [dispatch, projectId])

  useEffect(() => {
    (async () => { // effect calls are synchronous -
      // reset project related data on projectId change
      updateLoading(true)
      setCloseProtection(true)
      dispatch(RESET_PROJECT())

      // initialize project data in Redux
      try {
        await dispatch(FETCH_PROJECT(projectId))
        await Promise.all(
          [
            FETCH_ASSET_FOLDERS(),
            FETCH_GAME_LATEST(),
            FETCH_PROJECT_INDEX(),
            FETCH_PROJECT_LOCALES({targetLocale: locale}),
            FETCH_VOICE_PRESETS(),
          ].map((thunk: AsyncThunkAction<any, any, any>) => dispatch(thunk))
        )
        dispatch(GENERATE_CONTEXT_MAP())
        updateLoading(false)
      } catch (error) {
        dispatch(SET_ERROR(error as Error))
      }

      setCloseProtection(false)
    })()
    // re-trigger after re-index is completed
  }, [dispatch, locale, projectId, reIndexed])

  useSetPageTitle('Project', locale)

  if (error)   return <ErrorMessage error={error} />
  if (loading) return <Spinner />

  return (
    <>
      <Header>
        <Button onClick={() => updateShowConfirmation(true)}>Reindex</Button>
      </Header>
      <TopLevelContainer>
        {index.length > 0 ? (
          <>
            <TabNavigation>
              {Object.keys(tabs).map(key => (
                <Tab
                  disabled={disableTabNavigation}
                  isSelected={key === activeTab}
                  key={key}
                  onSelect={() => updateActiveTab(key)}
                >
                  {key}
                </Tab>
              ))}
            </TabNavigation>
            {tabs[activeTab]({
              locale,
              onDontHide: updateDisableTabNavigation,
            })}
          </>
        ): (
          <MessageContainer>
            <Message>Project information hasn't been indexed yet</Message>
          </MessageContainer>
        )}
        <ModalConfirmation
          header="Reindex Project"
          labelConfirm="Reindex"
          onCancel={onHideConfirmation}
          onConfirm={() => {
            triggerReindex(reIndexed)
            onHideConfirmation()
          }}
          onClose={onHideConfirmation}
          visible={showConfirmation}
        >
          <div>
            Reindexing the project may take a long time.
          </div>
          <div>
            Do you really want to start this process?
          </div>
        </ModalConfirmation>
      </TopLevelContainer>
    </>
  )
}
