import React from 'react';
import styled from 'styled-components';

import {LoadingSpinner} from '@dized/ui';

import {Header} from '../Header';

const StyledLoadingSpinner = styled(LoadingSpinner)`
  flex-grow: 1;
  justify-content: center;
`

export const Spinner = () => (
  <>
    <Header />
    <StyledLoadingSpinner
      height="0.5em"
      type="bar"
      width="50vh"
    />
  </>
)
