import {post} from '../../utils/apiRequest';

type TextData = Editor.TextData
type Translatable = Editor.Translatable
type TranslatableMap = Editor.TranslatableMap

const apiPath = '/api/v1/translation/trs'

type TextAPITranslatable = {
  locale: string
  text: string
  tuuid: string
  versionChecksum: string
}

export const fetchTR1s = async (trIds: string[], locale: string): Promise<TranslatableMap> => {
  if (trIds.length === 0) return {}

  // NOTE: use POST variant of API endpoint to avoid URL length restrictions
  const trs = await post(`${apiPath}/${locale}`, {
    fields: ['text', 'tuuid', 'versionChecksum'],
    trIds,
  }) as TextAPITranslatable[]

  // return targetId->Translatable map
  return trs.reduce(
    (map, {text, tuuid: id, versionChecksum: checksum}) => {
      const translatable: TextData = {checksum, id, locale, text}
      map[id] = translatable
      return map
    },
    {} as TranslatableMap
  )
}

export const saveTR1s = async (translatables: Translatable[]) => {
  if (translatables.length === 0) return

  const data = translatables.map(translatable => {
    const {checksum: versionChecksum, id: tuuid, locale, text} = translatable as TextData
    const data: TextAPITranslatable = {locale, text, tuuid, versionChecksum}
    return data
  })

  return post(apiPath, data)
}
