import {Editor} from 'slate';

type Translatable = Editor.Translatable

export const withIcons = (editor: Editor): Editor => {
  const {isInline, isVoid} = editor

  editor.isInline = n => n.type === 'icon' ? true : isInline(n)
  editor.isVoid = n => n.type === 'icon' ? true : isVoid(n)

  return editor
}

type EditorUserData = {
  locale: string
  source?: Translatable
}

export type UserDataEditor = {
  getUserData: () => EditorUserData
}

export const withUserData = (editor: Editor, data: EditorUserData) => {
  editor.getUserData = () => data

  return editor
}
