import React, {ChangeEvent} from 'react';

import {TextareaWithCounter} from '@dized/ui';

type TextInputProps = {
  onChange?: (value: string) => void
  value: string
}

export const TextInput = ({onChange, value}: TextInputProps) => (
  <TextareaWithCounter
    disabled={!onChange}
    onChange={onChange
      ? (event: ChangeEvent<HTMLTextAreaElement>) => onChange(event.target.value)
      : () => {} // prop marked as required
    }
    placeholder="Enter translation..."
    resizable
    showChars
    showWords
    value={value}
  />
)
