import React, {useCallback, useState} from 'react';

import {CloseAction, ContextEditor} from '../ContextEditor';
import {ContextList} from '../ContextList';
import {Filter} from '../Filter';

import {useAppSelector} from '../../app/hooks';
import {translationSelector} from '../../slices/translation';
import {setCloseProtection} from '../../utils/closeProtection';

interface ContextsProps {
  locale: string
  onDontHide: (donthide: boolean) => void
}

export const Contexts = ({locale, onDontHide}: ContextsProps) => {
  const {contexts, filteredList} = useAppSelector(translationSelector)
  const [editContextID, updateEditContextID] = useState<string>()

  const onEditContext = useCallback(
    (contextID: string | undefined) => {
      updateEditContextID(contextID)
      onDontHide(!!contextID)
    },
    [onDontHide]
  )

  const onClose = useCallback(
    (action: CloseAction, filteredList: string[], oldContextID: string) => {
      const index = filteredList.findIndex(id => id === oldContextID)
      let contextID;

      switch (action) {
        case 'previous':
          if (index > 0)
            contextID = filteredList[index - 1]
          break

        case 'next':
          if (index < filteredList.length - 1)
            contextID = filteredList[index + 1]
          break

        default:
          break
      }

      if (!contextID) setCloseProtection(false)
      onEditContext(contextID)
    },
    [onEditContext]
  )

  if (editContextID)
    return (
      <ContextEditor
        entries={Object.values(contexts[editContextID].translations)}
        locale={locale}
        onClose={action => onClose(action, filteredList, editContextID)}
      />
    )

  return (
    <>
      <Filter />
      <ContextList onEditContext={onEditContext} />
    </>
  )
}
