import React from 'react';
import styled from 'styled-components';

import {Header} from '../Header';
import {Message, MessageContainer} from '../StyledComponents';

const Details = styled.div`
  color: orange;
`

const errorMessages: {[key: string] : string} = {
  'forbidden':      'You are not authorized to access the project',
  'invalid locale': 'This locale has not been enabled for the project',
  'no locale':      'No target language specified',
  'no project':     'No project specified',
  'not found':      'Game or Project not found',
  'unauthorized':   'Not logged in',
}

const errorDetails: {[key: string] : string} = {
  'no locale':    'You should only start this tool via Org Portal',
  'no project':   'You should only start this tool via Org Portal',
  'unauthorized': 'Please go to Org Portal and log in there',
}

interface ErrorProps {
  details?: string
  error: Error
}

export const ErrorMessage = ({details, error}: ErrorProps) => {
  const type = error.message.toLowerCase()
  let message, more

  message = errorMessages[type]
  if (message) {
    details = errorDetails[type] ?? details
    if (details) more = <div>{details}</div>
  } else {
    message = 'Unknown error'
    more = (
      <>
        <div>{type}</div>
        <div>{error.name}</div>
        <div>{error.stack}</div>
      </>
    )
  }

  return (
    <>
      <Header />
      <MessageContainer>
        <Message>An error occured:</Message>
        <Message>{message}</Message>
        {more && <Details>{more}</Details>}
      </MessageContainer>
    </>
  )
}

export const NoLocale = () => <ErrorMessage error={new Error('no locale')}/>
export const NoProject = () => <ErrorMessage error={new Error('no project')}/>
