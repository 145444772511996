import React, {useCallback, useRef, useState} from 'react';
import styled from 'styled-components';

import {Button} from '@dized/ui';

import {TranslatableEditor} from './TranslatableEditor';

import type {ListenForChangesType} from '.';

type CloneCallback = Editor.CloneCallback
type IObject = TranslationIndex.IndexObject
type Translatable = Editor.Translatable

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledTranslatableEditor = styled(TranslatableEditor)`
  display: flex;
  align-items: center;
`;

type EntryEditorProps = {
  entry: IObject
  listenForChanges: ListenForChangesType
  locale: string
  onUpdate: (translatable: Translatable) => void
  source: Translatable
  translated?: Translatable
}

export const EntryEditor = ({
  entry,
  listenForChanges,
  locale,
  onUpdate,
  source,
  translated,
}: EntryEditorProps) => {
  const cloneRef = useRef<CloneCallback>()
  const [cloneCounter, updateCloneCounter] = useState<number>(0)
  const {targetChecksum} = entry;
  const {checksum} = translated ?? {}

  const onCopy = useCallback(
    async (entry: IObject, source: Translatable, locale: string, cloneCounter: number) => {
      const {current: cloneCallback} = cloneRef
      if (!cloneCallback) return

      // don't update editor state if cloning is handled internally
      const clone = await cloneCallback(source)
      if (!clone) return

      const {targetChecksum: checksum, targetId: id} = entry
      const translatable = {
        ...clone,
        checksum,
        id,
        locale,
      }
      onUpdate(translatable)
      updateCloneCounter(cloneCounter + 1)
    },
    [onUpdate]
  )

  return (
    <>
      <StyledTranslatableEditor
        entry={entry}
        value={source}
      />
      <ButtonContainer>
        <Button
          // DIZ-1717: disabled for now. Asset cloning should only
          // be enabled when source locale is NOT the master locale.
          // For now allow textual assets to be copied as translators
          // copy the old text as a source when editing rules.
          disabled={entry?.targetType !== 'tr1'}
          onClick={() => onCopy(entry, source, locale, cloneCounter)}
        >
          Copy
        </Button>
      </ButtonContainer>
      <StyledTranslatableEditor
        cloneRef={cloneRef}
        entry={entry}
        // we must force re-creation of component after cloning
        key={cloneCounter}
        listenForChanges={listenForChanges}
        locale={locale}
        onChange={onUpdate}
        source={source}
        status={
          checksum
            ? targetChecksum === checksum
              ? 'OK'
              : 'OUTDATED'
            : 'MISSING'
        }
        value={translated}
      />
    </>
  )
}
