import React from 'react';
import {RenderElementProps} from 'slate-react';

import {Icon} from './Icon';

export const Element = (props: RenderElementProps) => {
  const {attributes, children, element: {type}} = props

  switch (type) {
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>
    case 'icon':
      return <Icon {...props}/>
    default:
      return <p {...attributes}>{children}</p>
  }
}
