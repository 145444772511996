import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {RESET_PROJECT} from './project';
import {fetchTranslatables, saveTranslatables} from './translatable'
import {cloneAO1, saveAO1} from './translatable/ao1'
import {RootState} from '../app/store';


type IObject = TranslationIndex.IndexObject
type Translatable = Editor.Translatable
type TranslatableMap = Editor.TranslatableMap

type TranslationState = {
  source: TranslatableMap
  translated: TranslatableMap
}

const initialState: TranslationState = {
  source: {},
  translated: {},
}

export const CLONE_ASSET = createAsyncThunk(
  'CLONE_ASSET',
  async (
    {entry, source, locale} :
    {entry: IObject, source: Translatable, locale: string}
  ) => {
    const {id} = entry

    const updated = await cloneAO1(source, locale)

    return {id, translatable: updated}
  }
)

export const FETCH_CONTEXT_SOURCE = createAsyncThunk(
  'FETCH_CONTEXT_SOURCE',
  async ({entries, locale} : {entries: IObject[], locale: string}) =>
    fetchTranslatables(entries, locale)
)

export const FETCH_CONTEXT_TRANSLATED = createAsyncThunk(
  'FETCH_CONTEXT_TRANSLATED',
  async ({entries, locale} : {entries: IObject[], locale: string}) =>
    fetchTranslatables(entries, locale)
)

export const SAVE_CONTEXT_TRANSLATED = createAsyncThunk(
  'SAVE_CONTEXT_TRANSLATED',
  async ({entries, translatables} : {entries: IObject[], translatables: Translatable[]}) => {
    saveTranslatables(entries, translatables)

    return {entries, translatables}
  }
)

export const UPLOAD_ASSET = createAsyncThunk(
  'UPLOAD_ASSET',
  async ({entry, file, translatable} : {entry: IObject, file: File, translatable: Translatable}) => {
    const {id} = entry

    const updated = await saveAO1(translatable, file)

    return {id, translatable: updated}
  }
)

export const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    RESET_EDITOR: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(RESET_PROJECT, () => initialState)
      .addCase(CLONE_ASSET.fulfilled, (state, {payload: {id, translatable}}) => {
        const translated = {...state.translated}
        translated[id] = translatable

        return {
          ...state,
          translated,
        }
      })
      .addCase(FETCH_CONTEXT_SOURCE.fulfilled, (state, {payload: source}) => ({
        ...state,
        source,
      }))
      .addCase(FETCH_CONTEXT_TRANSLATED.fulfilled, (state, {payload: translated}) => ({
        ...state,
        translated,
      }))
      .addCase(SAVE_CONTEXT_TRANSLATED.fulfilled, (state, {payload: {entries, translatables}}) => {
        const translated = {...state.translated}

        entries.forEach(({id}, index) => translated[id] = translatables[index])

        return {
         ...state,
         translated,
        }
      })
      .addCase(UPLOAD_ASSET.fulfilled, (state, {payload: {id, translatable}}) => {
        const translated = {...state.translated}
        translated[id] = translatable

        return {
          ...state,
          translated,
        }
      })
      // After "Save" editor will be closed, so we do not need to update state
      //.addCase(SAVE_CONTEXT_TRANSLATED.fulfilled, ...)
  },
})

export const editorSelector = (state: RootState) => state[editorSlice.name]
export const {RESET_EDITOR} = editorSlice.actions
