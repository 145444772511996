export const generateTTSURL = (
  presets: VoicePreset[],
  voiceIndex: number,
  locale: string,
  text: string,
): string | undefined => {
  if (!text) return

  const settings = presets[voiceIndex]?.settings[locale];
  if (!settings) return

  const {engine, engineOptions, processingOptions} = settings;

  return (
    '/api/v1/tts/speak?' +
    `engine=${encodeURIComponent(engine)}&` +
    `${engineOptions ? `engineOptions=${encodeURIComponent(JSON.stringify(engineOptions))}&` : ''}` +
    `${processingOptions ? `processingOptions=${encodeURIComponent(JSON.stringify(processingOptions))}&` : ''}` +
    `text=${encodeURIComponent(text)}`
  );
};
