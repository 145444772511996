import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {RESET_PROJECT} from './project';
import {RootState} from '../app/store';
import {get, post} from '../utils/apiRequest';

type AssetState = {
  rootFolderId: string
  translationFolderId: string
}

const initialState: AssetState = {
  rootFolderId: '',
  translationFolderId: '',
}

//const apiPath = '/api/v1/assets'
const folderApiPath = '/api/v1/assets/folders'
const translationFolderName = 'Translation Assets'

type FolderData = {
  uuid: string
}

type Loopback3APIFolderResponseData = FolderData[]

const createFolder = async (name: string, organizationId: string, parentId: string) => {
  const now = new Date()
  return post(
    folderApiPath,
    {
      created: now,
      modified: now,
      name,
      organizationId,
      parent: parentId,
      parentId: parentId,
    }
   ) as Promise<FolderData>
}

const getFolder = async (where: object) => {
  const query = JSON.stringify({where})
  const [folder] =
    await get(`${folderApiPath}?filter=${encodeURIComponent(query)}`) as Loopback3APIFolderResponseData

  if (!folder)
    throw new Error(`Folder with filter '${query}' not found`)

  return folder
}

type FetchAssetFoldersResponse = {
  rootFolderId: string
  translationFolderId: string
}

export const FETCH_ASSET_FOLDERS = createAsyncThunk<
    FetchAssetFoldersResponse,
    void,
    {state: RootState}
  >(
  'FETCH_ASSET_FOLDERS',
  async (_, {getState}) => {
    const {
      game: {organizationId: orgIdGame},
      project: {organizationId: orgIdProject},
    } = getState()
    const organizationId = orgIdProject || orgIdGame
    const {uuid: rootFolderId} = await getFolder({parent: null, organizationId})

    let translationFolder
    try {
      translationFolder = await getFolder({
        name: translationFolderName,
        organizationId,
        parentId: rootFolderId,
      })
    } catch (_) {
      translationFolder = await createFolder(translationFolderName, organizationId, rootFolderId)
    }

    const {uuid: translationFolderId} = translationFolder
    return {rootFolderId, translationFolderId}
  }
)

export const assetSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    // no synchronous reducers at the moment
  },
  extraReducers: builder => {
    builder
      .addCase(RESET_PROJECT, () => initialState)
      .addCase(FETCH_ASSET_FOLDERS.fulfilled, (state, {payload}) => payload)
  },
})

export const assetSelector = (state: RootState) => state[assetSlice.name]
