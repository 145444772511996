import {useEffect} from 'react';

import {useAppSelector} from '../app/hooks';
import {gameSelector} from '../slices/game';

export const useSetPageTitle = (label: string, locale: string) => {
  const {
    title: {text} = {} as TranslationObject
  } = useAppSelector(gameSelector)

  useEffect(
    () => {
      if (text)
        document.title = `Dized - Translation Tool - ${label} - ${text} (${locale})`
    },
    [label, locale, text]
 )
}
