import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {RESET_PROJECT} from './project';
import {RootState} from '../app/store';
import {get} from '../utils/apiRequest';

type GameState = {
  organizationId: string
  tagline?: TranslationObject
  title?: TranslationObject
}

const initialState: GameState = {
  organizationId: '',
}

const apiPath = '/api/v1/ggl/games'

type GameLatestAPIResponse = {
  organizationId: string
  version: {
    tagline: string
    title: string
  }
}

export const FETCH_GAME_LATEST = createAsyncThunk<
    GameLatestAPIResponse,
    void,
    {state: RootState}
  >(
  'FETCH_GAME_LATEST',
  async (_, {getState}) => {
    const {project: {gameId}} = getState()

    return get(`${apiPath}/${gameId}/latest`) as Promise<GameLatestAPIResponse>
  },
)

// Game API has been designed before introduction of tr1
// Content is either raw string or JSON-stringified tr1
const upgradeToTR1 = (text: string): TranslationObject => {
  try {
    return JSON.parse(text)
  } catch (_) {
    // We don't require a real tr1, just something that looks like it
    return ({
      _type: 'tr1',
      text,
      tuuid: '',
    })
  }
}

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    // no synchronous reducers at the moment
  },
  extraReducers: builder => {
    builder
      .addCase(RESET_PROJECT, () => initialState)
      .addCase(FETCH_GAME_LATEST.fulfilled, (state, {
        payload: {
          organizationId,
          version: {tagline, title},
        }
      }) => ({
        organizationId,
        tagline: upgradeToTR1(tagline),
        title: upgradeToTR1(title),
      }))
  },
})

export const gameSelector = (state: RootState) => state[gameSlice.name]
