import React, {useEffect, useState} from 'react';
import {Editor, Transforms} from 'slate'
import {useSlate} from 'slate-react';
import styled from 'styled-components'

import {Button} from '@dized/ui'

import {AssetDropzone} from '../../AssetDropzone';

import {useAppSelector} from '../../../app/hooks';
import {assetSelector} from '../../../slices/asset';
import {getBaseUrl, getDownloadUrl, saveAO1} from '../../../slices/translatable/ao1';

const Cell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledAssetDropzone = styled(AssetDropzone)`
  max-height: 10vh;
`
const insertIcon = (editor: Editor, {attr, height, id}: IconData) => {
  const {selection} = editor
  if (!selection) return

  // Insert icon element at current selection...
  const {locale, size} = attr
  const icon: RichTextIcon = {
    attr: {...attr},
    children: [{text: ''}],
    height,
    id,
    type: 'icon',
    url: `${getBaseUrl(id, locale)}/download?size=${size}&max=true&min=false&ignoreAR=false`,
  }
  Transforms.insertNodes(editor, icon)

  // ...and then move selection after newly inserted node
  Transforms.move(editor, {distance: 1, unit: 'offset'})
}

type IconSelectorProps = {
  icon: IconData
  onClose: () => void
}

export const IconSelector = ({icon, onClose}: IconSelectorProps) => {
  const editor = useSlate()
  const {translationFolderId} = useAppSelector(assetSelector)
  const [url, updateURL] = useState<string>('')
  const {attr: {locale, mimeType: type}, id} = icon

  // generate initial URL
  useEffect(
    () => { // effect calls are synchronous
      (async () => {
        const baseURL = getBaseUrl(id, locale)
        const url = await getDownloadUrl(baseURL, '')
        updateURL(url)
      })()
    },
    [id, locale]
  )

  return (
    <>
      <Cell>
        <Button
          onClick={() => {
            insertIcon(editor, icon)
            onClose()
          }}
        >
          Select
        </Button>
      </Cell>
      <Cell>
        <StyledAssetDropzone
          assetData={{
            checksum: '',
            filename: '',
            id,
            locale,
            type,
            url,
          }}
          onFileSelected={async file => {
            const translatable = {
              checksum: 'not-available',
              id,
              locale,
            }
            const {url} = await saveAO1(translatable, file, translationFolderId)
            updateURL(url)
          }}
          // Our grid already takes care that the image is scaled down correctly.
          // Tell Asset component to render a simple unwrapped image element.
          showOriginal={true}
        />
      </Cell>
    </>
  )
}
