import React from 'react';
import styled from 'styled-components';

import {BlockButton} from './BlockButton';
import {IconButton} from './IconButton';
import {MarkButton} from './MarkButton';

const Spacer = styled.div`
  min-width: 1em;
`

const ToolbarContainer = styled.div`
  display: flex;
  flex-grow: 1;
  margin-bottom: 0.25em;
  border-bottom: dotted lightgrey 1px;
`

export const Toolbar = () => (
  <ToolbarContainer>
    <BlockButton format="heading-one">H1</BlockButton>
    <BlockButton format="heading-two">H2</BlockButton>
    <BlockButton format="heading-three">H3</BlockButton>
    <Spacer />
    <MarkButton format="bold">B</MarkButton>
    <MarkButton format="italic">I</MarkButton>
    <MarkButton format="underline">U</MarkButton>
    <MarkButton format="spoiler">Spoiler</MarkButton>
    <Spacer />
    <IconButton />
  </ToolbarContainer>
)
