import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {RESET_PROJECT} from './project';
import {RootState} from '../app/store';

type TargetCategory = TranslationIndex.TargetCategory

export const TargetCategories: TargetCategory[] = [
  // @TODO unfortunately DRY violation as you can't extract the values of an union type :-(
  //       At least type checking prevents adding of unkown categories
  'Block',
  'General',
  'Menu',
  'Question',
  'Rule',
  'Variable',
]

export type TargetCategoryFilterMap = { [Property in TargetCategory]: boolean }

export type FilterState = {
  categories: TargetCategoryFilterMap
  title: string
}

type ApplicationState = {
  error?: Error
  filter: FilterState
}

const initialState: ApplicationState = {
  filter: {
    categories: TargetCategories.reduce(
      (map, category) => {
        map[category] = true // set all categories to true by default
        return map
      },
      {} as TargetCategoryFilterMap
    ),
    title: '',
  },
}

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    SET_ERROR: (state, {payload}: PayloadAction<Error>) => {
      state.error = payload
    },
    SET_FILTER_CATEGORIES: ({filter}, {payload}: PayloadAction<TargetCategoryFilterMap>) => {
      filter.categories = payload
    },
    SET_FILTER_TITLE: ({filter}, {payload}: PayloadAction<string>) => {
      filter.title = payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(RESET_PROJECT, () => initialState)
      // make all rejected asynchronous actions throw by default
      .addMatcher(
        ({type}) => type.endsWith('/rejected'),
        (_, {error}) => {
          throw error
        }
      )
  }
})

export const applicationSelector = (state: RootState) => state[applicationSlice.name]
export const {SET_ERROR, SET_FILTER_CATEGORIES, SET_FILTER_TITLE} = applicationSlice.actions
