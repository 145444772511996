import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {RootState} from '../app/store';
import {get} from '../utils/apiRequest';

enum ProjectType {
  UNDEFINED = '',
  RULES = 'rct',
  TUTORIAL = 'tct',
}

type ProjectState = {
  gameId: string
  organizationId: string
  projectId: string
  type: ProjectType
}

const initialState: ProjectState = {
  gameId: '',
  organizationId: '',
  projectId: '',
  type: ProjectType.UNDEFINED,
}

const apiPath = '/api/v1/project/projects'

export const FETCH_PROJECT = createAsyncThunk(
  'FETCH_PROJECT',
  async (projectId: string) => get(`${apiPath}/${projectId}`) as
    Promise<{
      game: string
      organizationId: string
      type: ProjectType
      uuid: string
    }>
)

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    RESET_PROJECT: () => initialState,
    SET_GAME: (state, {payload}: PayloadAction<string>) => {
      state.gameId = payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(FETCH_PROJECT.fulfilled, (state, {
        payload: {game: gameId, organizationId, type, uuid: projectId}
      }) => ({
        gameId,
        organizationId,
        projectId,
        type,
      }))
  },
})

export const projectSelector = (state: RootState) => state[projectSlice.name]
export const {RESET_PROJECT, SET_GAME} = projectSlice.actions
