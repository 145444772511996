import {escape, pick} from 'lodash'
import {Descendant, Text} from 'slate'
import {jsx} from 'slate-hyperscript'

const TEXT_ELEMENTS = {
  H1: {type: 'heading-one'},
  H2: {type: 'heading-two'},
  H3: {type: 'heading-three'},
  P: {type: 'paragraph'},
}

const TEXT_MARKS = {
  EM: {italic: true},
  STRONG: {bold: true},
  U: {underline: true},
}

const emptyChildren = () => [text()]
const element = (children: Descendant[], attrs: Object): RichTextElement => jsx('element', attrs, children)
const fragment = (children: Descendant[]): Descendant[] => jsx('fragment', undefined, children)
const markedText = (child: Descendant, attrs: Object): RichText => jsx('text', attrs, child)
const text = (textContent?: string | null): RichText => ({text: textContent || ''})

const markChildren = (children: Descendant[], attrs: Object) =>
  children.map(child => markedText(child, attrs))

export const deserializeNode = (node: Node): RichText | RichText[] | RichTextElement => {
  const {nodeName, nodeType, textContent} = node

  switch (nodeType) {
    case Node.ELEMENT_NODE: {
      const htmlElement = node as HTMLElement

      let children = deserializeChildNodes(node)
      if (children.length === 0) children = emptyChildren()

      switch (nodeName) {
        // elements
        case 'H1':
        case 'H2':
        case 'H3':
        case 'P': {
          const attrs = TEXT_ELEMENTS[nodeName]
          return element(children, attrs)
        }

        // special elements
        case 'IMG': {
          const {dataset} = htmlElement
          const attrs: Partial<RichTextIcon> = {
            attr: {
              locale: '',
              mimeType: 'image/jpeg',
              size: '[16,16]', // from RCT - has special meaning for rule compiler
            },
            type: 'icon',
            ...pick(dataset, 'height', 'id', 'url'),
          }

          // parse attribute JSON
          try {
            const json = dataset.attr
            if (!json) throw new Error()
            attrs.attr = JSON.parse(json)
          } catch(_) {
            // use fallback defaults
          }

          return element(emptyChildren(), attrs)
        }

        // marked texts
        case 'EM':
        case 'STRONG':
        case 'U': {
          const attrs = TEXT_MARKS[nodeName]
          return markChildren(children, attrs)
        }

        // special marked texts
        case 'SPAN': {
          const {classList} = htmlElement;

          for (const className of Array.from(classList.values())) {
            switch (className) {
              case 'spoiler':
                return markChildren(children, {spoiler: true})
            }
          }
        }
      }
    }
  }

  // fallback when all else fails: just include text content as is
  return text(textContent)
}

// flatten (Descendant | Descendant[])[] to Descendant[]
const deserializeChildNodes = (node: Node) =>
  Array.from(node.childNodes).map(node => deserializeNode(node)).flat()

// input is a HTML fragment, not a HTML document
export const deserialize = (html?: string): Descendant[] => {
  const document = new DOMParser().parseFromString(html || '<p/>', 'text/html')
  const children = deserializeChildNodes(document.body)
  return fragment(children)
}

const serializeNode = (node: Descendant): string => {
  if (Text.isText(node)) {
    let text = escape(node.text)

    if (node.bold)      text = `<strong>${text}</strong>`
    if (node.italic)    text = `<em>${text}</em>`
    if (node.spoiler)   text = `<span class="spoiler" title="click to reveal">${text}</span>`
    if (node.underline) text = `<u>${text}</u>`

    return text
  }

  const {children, type} = node
  const html = serialize(children)

  switch (type) {
    case 'heading-one':
      return `<h1>${html}</h1>`
    case 'heading-two':
      return `<h2>${html}</h2>`
    case 'heading-three':
      return `<h3>${html}</h3>`
    case 'icon': {
      const {attr, height, id, url} = node as RichTextIcon
      const escapedURL = escape(url)

      return `<img
        src="${escapedURL}"
        alt=""
        data-attr="${escape(JSON.stringify(attr))}"
        data-height="${escape(height)}"
        data-id="${escape(id)}"
        data-type="IconWidget"
        data-url="${escapedURL}"
      />`.replace(/\s+/g, ' ')
    }
    case 'paragraph':
      return `<p>${html}</p>`
    default:
      return html
  }
}

// generates a HTML fragment, not a HTML document
export const serialize = (nodes: Descendant[]): string =>
  nodes.map(n => serializeNode(n)).join('')
