import React, {PropsWithChildren} from 'react';

import {ShellHeader} from '@dized/ui';

import {useAppSelector} from '../../app/hooks';
import {gameSelector} from '../../slices/game';

export const Header = ({children}: PropsWithChildren<{}>) => {
  const {
    title: {text} = {} as TranslationObject
  } = useAppSelector(gameSelector)

  return (
    <ShellHeader title={`Translation Tool${text ? ` - ${text}` : ''}`}>
      {children}
    </ShellHeader>
  )
}
