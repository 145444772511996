import {fetchAO1s} from './ao1'
import {fetchTR1s, saveTR1s} from './tr1'
import {fetchVO1s, saveVO1s} from './vo1'

type IObject = TranslationIndex.IndexObject
type TargetType = TranslationIndex.TargetType
type Translatable = Editor.Translatable
type TranslatableMap = Editor.TranslatableMap

const fetchTranslatablesOfType = async (
  entries: IObject[],
  locale: string,
  type: TargetType,
): Promise<TranslatableMap> => {
  const filtered = entries.filter(entry => entry.targetType === type)
  if (filtered.length === 0) return {}

  const targetIds = filtered.map(({targetId}) => targetId)
  let promise: Promise<TranslatableMap>

  switch (type) {
    case 'ao1':
      promise = fetchAO1s(targetIds, locale)
      break

    case 'tr1':
      promise = fetchTR1s(targetIds, locale)
      break

    case 'vo1':
      promise = fetchVO1s(targetIds, locale)
      break

    default:
      throw new Error(`unkown translatable type '${type}`)
  }

  // targetId->Translatable map
  const map = await promise

  // convert to entry id->Translatable map
  return filtered.reduce(
    (result, {id, targetId}) => {
      const translatable = map[targetId]
      if (translatable) result[id] = translatable
      return result
    },
    {} as TranslatableMap
  )
}

export const fetchTranslatables = async (entries: IObject[], locale: string) => {
  const [ao1s, tr1s, vo1s] = await Promise.all([
    fetchTranslatablesOfType(entries, locale, 'ao1'),
    fetchTranslatablesOfType(entries, locale, 'tr1'),
    fetchTranslatablesOfType(entries, locale, 'vo1'),
  ])

  return {
    ...ao1s,
    ...tr1s,
    ...vo1s,
  }
}

const saveTranslatablesOfType = async (
  entries: IObject[],
  translatables: Translatable[],
  type: TargetType,
) => {
  const filtered = entries
    .map(({targetType}, index) => targetType === type ? translatables[index] : undefined)
    // NOTE: Typescript data flow analysis doesn't detect that this filters out undefined
    .filter(translatable => translatable) as Translatable[]
  if (filtered.length === 0) return {}

  switch (type) {
    // case 'ao1': saved directly when file is uploaded

    case 'tr1':
      return saveTR1s(filtered)

    case 'vo1':
      return saveVO1s(filtered)

    default:
      throw new Error(`unkown translatable type '${type}`)
  }
}

export const saveTranslatables = async (entries: IObject[], translatables: Translatable[]) =>
  Promise.all([
    // saveTranslatablesOfType(entries, translatables, 'ao1'), are updated directly via file upload
    saveTranslatablesOfType(entries, translatables, 'tr1'),
    saveTranslatablesOfType(entries, translatables, 'vo1'),
  ])
