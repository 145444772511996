import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';

import {Button, CycleButton} from '@dized/ui';

import {AssetDropzone} from '../../AssetDropzone';
import {MessageContainer, Message, Row} from '../../StyledComponents';
import {TextInput} from '../../TextInput';
import {TTSButton} from '../../TTSButton';
import {WidgetProps} from '.';

import {useAppSelector} from '../../../app/hooks';
import {assetSelector} from '../../../slices/asset';
import {cloneAO1, saveAO1} from '../../../slices/translatable/ao1';

type AssetData = Editor.AssetData
type TextData = Editor.TextData
type Translatable = Editor.Translatable
type VoiceOverData = Editor.VoiceOverData

const StyledButton = styled(Button)`
  min-width: fit-content;
`

const StyledRow = styled(Row)`
  margin-bottom: 0.5em;
`

export const VO1 = (props: WidgetProps) => {
  const {checksum, cloneRef, entry, listenForChanges, locale, onChange, source, value} = props
  const {translationFolderId} = useAppSelector(assetSelector)
  const [callbackRegistered, updateCallbackRegistered] = useState(false)
  const [fallbackText, updateFallbackText] = useState<string>()
  // NOTE: clone properties from source for untranslated value
  const voiceOverData = (value ?? source) as VoiceOverData
  const {preset, ttsEnabled} = voiceOverData

  const doMerge = useCallback(
    (value: Translatable | undefined, merge: object) => {
      if (!onChange) return

      const {assetId, id, preset, textId, ttsEnabled} = source as VoiceOverData
      const translatable = {
        ...(value ?? {
          assetId,
          checksum: '',
          id,
          locale,
          preset,
          textId,
          ttsEnabled,
        }),

        // override some values from the provided update
        ...merge,
      }
      onChange(translatable)
    },
    [locale, onChange, source]
  )

  const onCopy = useCallback(
    async (source: VoiceOverData) => {
      const {
        assetId,
        checksum,
        filename,
        locale: sourceLocale,
        preset,
        text,
        textId,
        ttsEnabled,
        type
      } = source
      let {url} = source;

      if (!ttsEnabled) {
        const translatable = {
          checksum,
          id: assetId,
          locale: sourceLocale,
        }
        const cloned = await cloneAO1(translatable, locale)
        url = cloned.url // URL includes locale, hence we need to update it
      }

      return {assetId, filename, preset, text, textId, ttsEnabled, type, url} as VoiceOverData
    },
    [locale]
  )
  cloneRef.current = onCopy

  const onFallbackChanged = useCallback(
    (translatable?: Translatable) => {
      if (!translatable) return

      const {text} = translatable as TextData
      updateFallbackText(text)
    },
    []
  )

  // register a listener callback for the fallback TR1 associated with this VO1
  useEffect(
    () => {
      // callback must only be registered once
      if (callbackRegistered) return
      updateCallbackRegistered(true)

      const {meta: {fallbackTUUID}} = entry
      if (!listenForChanges || !fallbackTUUID) return

      listenForChanges(fallbackTUUID, onFallbackChanged)
    },
    [entry, callbackRegistered, listenForChanges, onFallbackChanged]
  )

  return (
    <>
      {onChange && (
        <StyledRow>
          <CycleButton
            onChange={(ttsEnabled: string) =>
              doMerge(value, {
                checksum,
                ttsEnabled: ttsEnabled === 'true',
              })
            }
            options={[
              {label: 'Text to Speech', value: 'true'},
              {label: 'Asset', value: 'false'},
            ]}
            value={ttsEnabled.toString()}
          />
          {ttsEnabled && (
            <>
              <StyledButton
                disabled={!fallbackText}
                onClick={() => doMerge(value, {checksum, text: fallbackText})}
              >
                Copy Translation
              </StyledButton>
              <TTSButton
                fallbackText={fallbackText}
                locale={locale}
                preset={preset}
                value={value as VoiceOverData}
              />
              </>
          )}
        </StyledRow>
      )}
      {ttsEnabled
        ? (
          <TextInput
            onChange={onChange && (text => doMerge(value, {checksum, text}))}
            value={(value as TextData)?.text ?? ''}
          />
        ) :
      // always allow asset drag & drop for translation
      (onChange || (value as AssetData).type)
        ? (
          <AssetDropzone
            assetData={value as AssetData}
            disabled={!onChange}
            onFileSelected={async (file) => {
              const {assetId: id} = voiceOverData
              const translatable = {
                checksum,
                id, // this may be empty -> new asset will be created...
                locale,
              }
              const {filename, id: assetId, type, url} =
                await saveAO1(translatable, file, translationFolderId)

              // ... and this makes sure to store the new asset ID
              doMerge(value, {assetId, checksum, filename, type, url})
            }}
          />
        )
        // source asset is missing
        : (
          <MessageContainer>
            <Message>
              An asset has not been selected for the voiceover.
              This might be an error in the tutorial.
            </Message>
          </MessageContainer>
        )
      }
    </>
  )
}
