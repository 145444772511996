import React, {Fragment, ReactNode, useCallback, useEffect, useState} from 'react';
import {AsyncThunkAction} from '@reduxjs/toolkit';
import styled from 'styled-components';

import {Button, ModalConfirmation} from '@dized/ui';

import {EntryEditor} from './EntryEditor';
import {Row} from '../StyledComponents';
import {Spinner} from '../Spinner';
import {Message, MessageContainer} from '../StyledComponents';

import {useAppSelector, useAppDispatch} from '../../app/hooks';
import {SET_ERROR} from '../../slices/application';
import {
  editorSelector,
  FETCH_CONTEXT_SOURCE,
  FETCH_CONTEXT_TRANSLATED,
  RESET_EDITOR,
  SAVE_CONTEXT_TRANSLATED,
} from '../../slices/editor';
import {translationSelector} from '../../slices/translation';
import {setCloseProtection} from '../../utils/closeProtection';

// NOTE: not supported by babel-plugin-transform-typescript
// import IObject = TranslationIndex.IndexObject;
type IObject = TranslationIndex.IndexObject
type Translatable = Editor.Translatable

const Entries = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 0.5em;
  grid-row-gap: 0.5em;
  margin-top: 0.5em;
`

const EntryTitle = styled.div`
  grid-column: 1 / -1;
  padding: 0.2em;
  background-color: lightgrey;
  border-radius: 4px;
`

const MissingEntry = styled(MessageContainer)`
  grid-column: 1 / -1;
`

export type CloseAction = 'next' | 'none' | 'previous'

type ListenForChangesCallback = (translatable?: Translatable) => void
type ListenCallbackMap = {[id: string]: ListenForChangesCallback[]}
export type ListenForChangesType =
  (targetId: string, callback: ListenForChangesCallback) => void

const generateListenCallbackMap = (entries: IObject[]) =>
  entries.reduce(
    (map, {id}) => {
      map[id] = []
      return map
    },
    {} as ListenCallbackMap
  )

type ConfirmationType = 'close' | 'revert' | undefined

type ContextEditorProps = {
  entries: IObject[]
  hideClose?: boolean,
  hideTitle?: boolean,
  locale: string
  onClose: (action: CloseAction) => void
}

export const ContextEditor = ({
  entries,
  hideClose,
  hideTitle,
  locale,
  onClose,
}: ContextEditorProps) => {
  const {source, translated} = useAppSelector(editorSelector)
  const {masterLocale} = useAppSelector(translationSelector)
  const dispatch = useAppDispatch()
  const [loading, updateLoading] = useState<boolean>(true)
  const [showConfirmation, updateShowConfirmation] = useState<ConfirmationType>()
  const [showError, updateShowError] = useState<ReactNode>()
  const [changes, updateChanges] = useState<Translatable[]>([])
  const [listenCallbacks, updateListenCallbacks] = useState<ListenCallbackMap>({})

  const [{targetContextGroupLabel}] = entries
  const changed = changes.some(translatable => !!translatable)

  useEffect(() => {
    (async () => { // effect calls are synchronous -
      // reset editor related data on
      updateLoading(true)
      updateListenCallbacks(generateListenCallbackMap(entries))
      dispatch(RESET_EDITOR())

      try {
        await Promise.all(
          [
            FETCH_CONTEXT_SOURCE({entries, locale: masterLocale}),
            FETCH_CONTEXT_TRANSLATED({entries, locale}),
          ].map((thunk: AsyncThunkAction<any, any, any>) => dispatch(thunk))
        )
        updateLoading(false)
      } catch (error) {
        dispatch(SET_ERROR(error as Error))
      }
    })()
  }, [dispatch, entries, locale, masterLocale])

  // changed only ever changes from "false" to "true"
  useEffect(
    () => setCloseProtection(changed),
    [changed]
  )

  const onCloseCheck = useCallback(
    (changed: boolean) => {
      if (!changed) return onClose('none')
      updateShowConfirmation('close')
    },
    [onClose]
  )

  const onCloseConfirmation = useCallback(
    (type: ConfirmationType) => {
      switch (type) {
        case 'close':
          onClose('none')
          break;

        case 'revert':
          updateChanges([])
          updateListenCallbacks(generateListenCallbackMap(entries))

          // setting the loading flag will destroy the existing components
          updateLoading(true);

          // clearing it will render components from scratch using Redux state
          setImmediate(() => updateLoading(false))
          break;
      }
      updateShowConfirmation(undefined)
    },
    [entries, onClose]
  )

  const onHideConfirmation = useCallback(
    () => updateShowConfirmation(undefined),
    []
  )

  const onHideError = useCallback(
    () => updateShowError(undefined),
    []
  )

  /*
   * NOTE: this callback isn't stable, it changes when onSave() is called.
   *       It's the users responsibilty that a callback is registered only
   *       once during initialization!
   */
  const onListenForChanges = useCallback(
    (targetId: string, callback: ListenForChangesCallback) => {
      const entry = entries.find(({targetId: id}) => id === targetId)
      if (!entry) return

      const {id} = entry

      // functional state update to reduce dependencies
      updateListenCallbacks(listenCallbacks => {
        const callbacks = listenCallbacks[id]
        if (!callbacks) return listenCallbacks

        // add callback to list
        return {
          ...listenCallbacks,
          [id]: [
            ...callbacks,
            callback,
          ],
        }
      })

      // provide initial translated value
      callback(translated[id])
    },
    [entries, translated]
  )

  const onRevertCheck = useCallback(
    () => updateShowConfirmation('revert'),
    []
  )

  const onSave = useCallback(
    async (changes: Translatable[], changed: boolean = true, action?: CloseAction) => {
      try {
        if (changed) {
          await dispatch(SAVE_CONTEXT_TRANSLATED({
            entries: entries.filter((_, index) => changes[index]),
            translatables: changes.filter(changed => changed),
          }))
          updateChanges([])
        }
        if (action) onClose(action)
      } catch (error) {
        updateShowError((
          <>
            <div>An error occured</div>
            <div>{(error as Error).message}</div>
          </>
        ))
      }
    },
    [dispatch, entries, onClose]
  )

  const onUpdate = useCallback(
    (
      translatable: Translatable,
      index: number,
      callbacks: ListenForChangesCallback[],
    ) => {
      // functional state update to reduce dependencies
      updateChanges(changes => {
        const newState = [...changes]
        newState[index] = {...translatable}
        return newState}
      )

      // provide updated translated content
      callbacks.forEach(callback => callback(translatable))
    },
    []
  )

  if (loading) return <Spinner />

  return (
    <>
      {hideTitle || (
        <>
          <Row>
            <h2>{targetContextGroupLabel}</h2>
          </Row>
          <Row>
            {hideClose || (
              <Button
                onClick={() => onCloseCheck(changed)}
              >
                Close
              </Button>
            )}
            <Button
              disabled={!changed}
              onClick={onRevertCheck}
            >
              Revert Changes
            </Button>
            {hideClose || (
              <Button
                onClick={() => onSave(changes, changed, 'previous')}
              >
                {changed && 'Save & '}Goto Prev.
              </Button>
            )}
            <Button
              disabled={!changed}
              onClick={() => onSave(changes)}
            >
              Save
            </Button>
            {hideClose || (
              <Button
                onClick={() => onSave(changes, changed, 'next')}
              >
                {changed && 'Save & '}Goto Next
              </Button>
            )}
          </Row>
        </>
      )}
      <Entries>
        {entries.map((entry, index) => {
          const {id, targetContext} = entry
          const sourceTranslatable = source[id]

          return (
            <Fragment key={index}>
              <EntryTitle>
                {targetContext}
              </EntryTitle>
              {sourceTranslatable
                ? (
                  <EntryEditor
                    entry={entry}
                    listenForChanges={onListenForChanges}
                    locale={locale}
                    onUpdate={translatable =>
                      onUpdate(translatable, index, listenCallbacks[id])
                    }
                    source={sourceTranslatable}
                    translated={changes[index] || translated[id]}
                  />
                ) : (
                  <MissingEntry>
                    <Message>
                      NOTHING TO TRANSLATE - asset has been deleted
                    </Message>
                  </MissingEntry>
                )
                }
            </Fragment>
          )
        })}
      </Entries>
      <ModalConfirmation
        header="Discard Changes?"
        labelConfirm="Discard"
        onCancel={onHideConfirmation}
        onConfirm={() => onCloseConfirmation(showConfirmation)}
        onClose={onHideConfirmation}
        visible={!!showConfirmation}
      >
        You have made changes. Do you really want to discard them?
      </ModalConfirmation>
      <ModalConfirmation
        header="Error"
        labelCancel="Close"
        onCancel={onHideError}
        onClose={onHideError}
        visible={!!showError}
      >
        {showError || ''}
      </ModalConfirmation>
    </>
  )
}
