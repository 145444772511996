import React, {PropsWithChildren} from 'react';
import {Editor, Element as SlateElement, Transforms} from 'slate'
import {useSlate} from 'slate-react';

import {ToolbarButton} from './ToolbarButton';

const isBlockActive = (editor: Editor, format: RichTextElementType) => {
  const {selection} = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    })
  )

  return !!match
}

const setBlock = (editor: Editor, format: RichTextElementType) => {
  const isActive = isBlockActive(editor, format)
  const newProperties: Partial<SlateElement> = {
    type: isActive ? 'paragraph' : format,
  }

  Transforms.setNodes(editor, newProperties)
}

type BlockButtonProps = PropsWithChildren<{
  format: RichTextElementType
}>

export const BlockButton = ({children, format}: BlockButtonProps) => {
  const editor = useSlate()

  return (
    <ToolbarButton
      active={isBlockActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        setBlock(editor, format)
      }}
    >
      {children}
    </ToolbarButton>
  )
}
