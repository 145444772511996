import React, {useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {AsyncThunkAction} from '@reduxjs/toolkit';

import {Button} from '@dized/ui';

import {ContextEditor} from '../ContextEditor';
import {ErrorMessage} from '../ErrorMessage';
import {Header} from '../Header';
import {Spinner} from '../Spinner';
import {Message, MessageContainer, TopLevelContainer} from '../StyledComponents';

import {useAppSelector, useAppDispatch} from '../../app/hooks';
import {useSetPageTitle} from '../../hooks/useSetPageTitle';
import {applicationSelector, SET_ERROR} from '../../slices/application';
import {FETCH_ASSET_FOLDERS} from '../../slices/asset';
import {FETCH_GAME_LATEST} from '../../slices/game';
import {RESET_PROJECT, SET_GAME} from '../../slices/project';
import {
  translationSelector,
  FETCH_GAME_INDEX,
  FETCH_GAME_LOCALES,
  REINDEX_GAME,
} from '../../slices/translation';
import {setCloseProtection} from '../../utils/closeProtection';

type RouteParams = {
  gameId: string
  locale: string
}

export const Game = () => {
  const {locale, gameId} = useParams<RouteParams>()
  const {error} = useAppSelector(applicationSelector)
  const {index} = useAppSelector(translationSelector)
  const dispatch = useAppDispatch()
  const [loading, updateLoading] = useState<boolean>(true)
  const [reIndexed, updateReIndexed] = useState<boolean>(false)

  const triggerReindex = useCallback(async (reIndexed: boolean) => {
    updateLoading(true)

    try {
      setCloseProtection(true)
      await dispatch(REINDEX_GAME(gameId))
      updateReIndexed(!reIndexed)
    } catch (error) {
      dispatch(SET_ERROR(error as Error))
    }

    setCloseProtection(false)
  }, [dispatch, gameId])

  useEffect(() => {
    (async () => { // effect calls are synchronous -
      // reset game related data on gameId change
      updateLoading(true)
      setCloseProtection(true)
      dispatch(RESET_PROJECT())
      dispatch(SET_GAME(gameId))

      // initialize game data in Redux
      try {
        await dispatch(FETCH_GAME_LATEST())
        await Promise.all(
          [
            FETCH_ASSET_FOLDERS(),
            FETCH_GAME_INDEX(),
            FETCH_GAME_LOCALES({targetLocale: locale}),
          ].map((thunk: AsyncThunkAction<any, any, any>) => dispatch(thunk))
        )
        updateLoading(false)
      } catch (error) {
        dispatch(SET_ERROR(error as Error))
      }

      setCloseProtection(false)
    })()
    // re-trigger after re-index is completed
  }, [dispatch, locale, gameId, reIndexed])

  useSetPageTitle('Game', locale)

  if (error)   return <ErrorMessage error={error} />
  if (loading) return <Spinner />

  return (
    <>
      <Header>
        <Button onClick={() => triggerReindex(reIndexed)}>Reindex</Button>
      </Header>
      <TopLevelContainer>
        {index.length > 0 ? (
          <ContextEditor
            entries={index}
            hideClose
            locale={locale}
            onClose={() => setCloseProtection(false)}
          />
        ) : (
          <MessageContainer>
            <Message>Game information hasn't been indexed yet</Message>
          </MessageContainer>
        )}
      </TopLevelContainer>
    </>
  )
}
