export const apiRequest = async (url: string, method: string, body?: object) => {
  const response = await fetch(url, {
    cache: 'no-cache',
    method,
    ...body
  })

  if (!response.ok)
    throw new Error(response.statusText)

  const contentType = response.headers.get('content-type')
  if (!contentType || !contentType.includes('application/json'))
    throw new TypeError('expected JSON response')

  return response.json()
}

export const get = (url: string) => apiRequest(url, 'GET')

export const post = (url: string, data: any) => apiRequest(url, 'POST', {
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(data),
})
