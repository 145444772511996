import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import styled from 'styled-components';

import {NoLocale, NoProject} from './components/ErrorMessage';
import {Game} from './components/Game';
import {Project} from './components/Project';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

function App() {
  return (
    <Container>
      <BrowserRouter>
        <Switch>
          <Route path="/game/:gameId/:locale" component={Game} />
          <Route path="/game/:gameId" component={NoLocale} />
          <Route path="/project/:projectId/:locale" component={Project} />
          <Route path="/project/:projectId" component={NoLocale} />
          {/* old backwards compatible routes - must be last */}
          <Route path="/:projectId/:locale" component={Project} />
          <Route path="/:projectId" component={NoLocale} />
          <Route exact path="/" component={NoProject} />
        </Switch>
      </BrowserRouter>
    </Container>
  );
}

export default App;
